import React, { useEffect, useState, useRef, forwardRef } from 'react';
import Slogan from '../slogan';
import * as styles from './index.module.scss';

const Preloader = () => {
  return (
    <div className={`${styles.preloader}`}>
        <Slogan spin={true} />
    </div>
  );
};

export default Preloader;
