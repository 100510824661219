import React, { forwardRef, useState, useEffect, useRef } from 'react';
import * as styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { closeMenu, openMenu } from '../../../state/ui';
import { setCanToggleMenu } from '../../../state/ui';
import gsap, { Back } from 'gsap';
const MenuButton = () => {
  const dispatch = useDispatch();
  const menuOpen = useSelector((state) => state.ui.menuOpen);
  const canToggleMenu = useSelector((state) => state.ui.canToggleMenu);
  const menuAnimationTime = useSelector((state) => state.ui.menuAnimationTime);
  const jumpSection = useSelector((state) => state.home.jumpSection);
  let handleCanToggleTimeout = null;
  const firstUpdate = useRef(true);
  const ref = useRef();
  const handleToggle = () => {
    if (canToggleMenu) {
      dispatch(setCanToggleMenu(false));
      menuOpen ? dispatch(closeMenu()) : dispatch(openMenu());
      handleCanToggleTimeout = setTimeout(() => {
        dispatch(setCanToggleMenu(true));
      }, menuAnimationTime * 1000);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup
      if (handleCanToggleTimeout !== null) {
        clearTimeout(handleCanToggleTimeout);
      }
    };
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    animateJumpSection();
  }, [jumpSection]);

  // animates when landing section makes a jump
  const animateJumpSection = () => {
    gsap
      .timeline()
      .add(
        gsap.to(ref.current, {
          duration: 0.75,
          ease: Back.easeOut,
          x: 200,
        })
      )
      .add(
        gsap.to(ref.current, {
          duration: 0.75,
          delay: 0.15,
          ease: Back.easeInOut,
          x: 0,
        })
      );
  };

  const lineStyle = {
    transition: `stroke-dasharray ${
      menuAnimationTime * 1000
    }ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset ${
      menuAnimationTime * 1000
    }ms cubic-bezier(0.4, 0, 0.2, 1)`,
  };

  return (
    <div
      className={`${styles.menu} ${menuOpen ? styles.opened : ''}`}
      onClick={handleToggle}
      aria-label='Main Menu'
      ref={ref}
    >
      <svg width='100%' viewBox='0 0 100 100'>
        <path
          style={lineStyle}
          className={`${styles.line} ${styles.line1}`}
          d='M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058'
        />
        <path
          style={lineStyle}
          className={`${styles.line} ${styles.line2}`}
          d='M 20,50 H 80'
        />
        <path
          style={lineStyle}
          className={`${styles.line} ${styles.line3}`}
          d='M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942'
        />
      </svg>
    </div>
  );
};

export default MenuButton;
