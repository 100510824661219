import React, { useState, useEffect } from 'react';
import * as styles from './index.module.scss';
import { Transition } from 'react-transition-group';
import gsap, { Cubic } from 'gsap';
import Cylinder from '../../../assets/images/shapes/cylinder.png';
import { useSelector, useDispatch } from 'react-redux';
import navLinks from '../../../constants/navLinks';
import WipeLink from '../../nav-transitions/wipe-link';
import { SliderButton } from '@typeform/embed-react';
import { closeMenu } from '../../../state/ui';
const MenuTransition = (props) => {
  const duration = useSelector((state) => state.ui.menuAnimationTime);
  const menuOpen = useSelector((state) => state.ui.menuOpen);
  const onHomeScrollable = useSelector((state) => state.nav.onHomeScrollable);
  const [sideNavEls, setSideNavEls] = useState([]);
  const dispatch = useDispatch();

  const menuEnter = (el) => {
    gsap.from(el, {
      duration: duration,
      opacity: 0,
      onComplete: () => {},
    });

    // const scrollIndicator = gsap.to(scrollIndicatorRef.current, {
    //   duration: duration / 2,
    //   ease: Cubic.easeIn,
    //   y: 50,
    //   opacity: 0,
    // });

    const dividerLines = [];
    const textLinksLeft = [];
    const textLinksRight = [];
    const addresses = [];
    for (let k = 0; k < el.firstChild.firstChild.children.length; k++) {
      const element = el.firstChild.firstChild.children[k].children[1];
      textLinksLeft.push(el.firstChild.firstChild.children[k].children[0]);
      if (el.firstChild.firstChild.children[k].children[2]) {
        textLinksRight.push(el.firstChild.firstChild.children[k].children[2]);
      }
      dividerLines.push(element);
    }

    // animates the line thickness of each divider
    gsap.from(dividerLines, {
      height: 20,
      opacity: 0,
      delay: 0.1,
      duration: duration,
      ease: Cubic.easeInOut,
      stagger: 0.05,
    });

    // animates links box size
    gsap.from(textLinksLeft, {
      duration: duration,
      opacity: 0,
      // ease: Cubic.easeOut,
      x: -50,
      stagger: 0.05,
      clearProps: 'opacity',
    });
    gsap.from(textLinksRight, {
      duration: duration,
      opacity: 0,
      // ease: Cubic.easeOut,
      x: 50,
      stagger: 0.05,
      clearProps: 'opacity',
    });

    // animates the office addresses
    gsap.from(el.firstChild.lastChild.children, {
      duration: duration,
      opacity: 0,
      stagger: 0.05,
      delay: 0.1,
      ease: Cubic.easeOut,
      y: 30,
    });
  };

  const menuLeave = (el) => {
    const background = gsap.to(el, {
      duration: duration,
      opacity: 0,
      onComplete: () => {},
    });

    // if (onHomeScrollable && sideNavEls[0]) {
    //   const sideNav = gsap.to(sideNavEls[0], {
    //     duration: duration,
    //     ease: Cubic.easeOut,
    //     stagger: -0.05,
    //     x: 0,
    //     opacity: 1,
    //   });
    // } else {
    //   const sideNav = gsap.to(sideNavEls, {
    //     duration: duration,
    //     ease: Cubic.easeOut,
    //     stagger: -0.05,
    //     x: 0,
    //     opacity: 1,
    //   });
    // }

    // const scrollIndicator = gsap.to(scrollIndicatorRef.current, {
    //   duration: duration,
    //   ease: Cubic.easeIn,
    //   y: 0,
    //   opacity: 1,
    // });
  };

  return (
    <Transition
      timeout={duration * 1000}
      mountOnEnter
      unmountOnExit
      in={menuOpen}
      onEnter={menuEnter}
      onExit={menuLeave}
    >
      <Menu {...props} />
    </Transition>
  );
};

const Menu = ({
  in: show,
  // onMenuToggleEnd,
  // scrollIndicatorRef,
}) => {
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  const dispatch = useDispatch();
  const addresses = [
    {
      name: 'Dublin',
      address: 'Lucky Beard Dublin, 15 Harcourt Street, Dublin 2, D02 XY47',
    },
    {
      name: 'London',
      address:
        'Rooms F10 & F11, First Floor, West Wing, Somerset House, Strand, London WC2R 1LA',
    },
    {
      name: 'Pretoria',
      address:
        'Southdowns Ridge Office Park, Cnr. Nelmapius & John Vorster Ave, Irene, Centurion 0123',
    },
    {
      name: 'Johannesburg',
      address:
      '37 Bath Avenue, Rosebank, Johannesburg, 2193',
    },
  ];

  const leftEnter = (i) => {
    setLeftHover(i);
  };
  const leftLeave = (i) => {
    setLeftHover(-1);
  };

  const rightEnter = (i) => {
    setRightHover(i);
  };

  const rightLeave = (i) => {
    setRightHover(-1);
  };

  return (
    <div
      className={styles.menuContainer}
      style={{ backgroundImage: `url(${Cylinder})` }}
    >
      <div className={styles.menuInner}>
        <div className={styles.links}>
          {navLinks.map((navItem, i) => (
            <div key={`link-${i}`} className={styles.row}>
              {navItem[0].type === 'path' ? (
                <WipeLink to={navItem[0].path}>
                  <span
                    onMouseEnter={() => {
                      leftEnter(i);
                    }}
                    onMouseLeave={() => {
                      leftLeave(i);
                    }}
                    onClick={() => {
                      dispatch(closeMenu());
                    }}
                    className={`${styles.link} `}
                  >
                    {navItem[0].name}{' '}
                  </span>
                </WipeLink>
              ) : (
                <span
                  onMouseEnter={() => {
                    leftEnter(i);
                  }}
                  onMouseLeave={() => {
                    leftLeave(i);
                  }}
                >
                  <SliderButton className={`${styles.link} `} id='l56iJtWS'>
                    {navItem[0].name}{' '}
                  </SliderButton>
                </span>
              )}

              <div
                className={`${styles.dividerLine} ${
                  i === leftHover ? styles.left : null
                } ${i === rightHover ? styles.right : null} ${
                  navItem[1] ? '' : styles.noMargin
                }`}
              >
                <div className={styles.inner} />
              </div>
              {navItem[1] ? (
                <WipeLink to={navItem[1].path}>
                  <span
                    onClick={() => {
                      dispatch(closeMenu());
                    }}
                    onMouseEnter={() => {
                      rightEnter(i);
                    }}
                    onMouseLeave={() => {
                      rightLeave(i);
                    }}
                    className={`${styles.link} ${styles.right}`}
                  >
                    {navItem[1].name}
                  </span>
                </WipeLink>
              ) : null}
            </div>
          ))}
        </div>
        <div className={styles.offices}>
          {addresses.map((item) => (
            <div key={item.name} className={styles.address}>
              <h4> {item.name} </h4>
              <p> {item.address} </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuTransition;
