import React, { useRef, useEffect } from 'react';
import gsap, { Linear } from 'gsap';
import * as styles from './index.module.scss';
import sloganSVG from '../../assets/images/slogan.svg';
import { Samy, SvgProxy } from 'react-samy-svg';

const Slogan = ({ spin, width }) => {
  const sloganRef = useRef();

  useEffect(() => {
    if (spin) {
      gsap.to(sloganRef.current, {
        transformOrigin: '50% 50%',
        repeat: -1,
        duration: 50,
        ease: Linear.easeNone,
        rotate: -360,
      });
    }
  }, []);

  return (
    <div style={{ width: width ? width : 170, height: width ? width : 170, alignSelf: 'center' }} ref={sloganRef}>
      <Samy path={sloganSVG} >
        <SvgProxy
          selector={'path'}
          width={'100%'}
          height={'100%'}
          fill={'#35333E'}
          stroke={'none'}
          strokeWidth='0px'
        />
      </Samy>
    </div>
  );
};

export default Slogan;
