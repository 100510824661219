import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import gsap, { Cubic, Back, Linear } from 'gsap';
import ArrowDown from '../arrow-down';
import useCombinedRefs from '../../hooks/useCombinedRefs';
import { useSelector } from 'react-redux';
const ScrollDownIndicator = ({ goToScrollableSection }, ref) => {
  const [scrollDownHover, setScrollDownHover] = useState(false);
  const firstUpdate = useRef(true);
  const combinedRef = useCombinedRefs(ref);
  const menuOpen = useSelector((state) => state.ui.menuOpen);
  const jumpSection = useSelector((state) => state.home.jumpSection);
  const onLandingSection = useSelector((state) => state.nav.onLandingSection);
  const onHomeScrollable = useSelector((state) => state.nav.onHomeScrollable);
  const arrowRef = useRef();

  useEffect(() => {
    //  animate up and down
    const duration = 1.75;
    gsap.to(arrowRef.current, {
      duration: duration,
      transformOrigin: 'bottom center',
      scaleY: 0.87,
      ease: Linear.easeNone,
      yoyo: true,
      repeat: -1,
      // delay:0.3
    });

    gsap.to(combinedRef.current.querySelector('h5'), {
      duration: duration,
      y: 10,
      ease: Linear.easeNone,
      yoyo: true,
      repeat: -1,
    });
  }, []);

  useEffect(() => {
    if (onHomeScrollable) {
      animateOutLanding();
    }
  }, [onHomeScrollable]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    animateJumpSection();
  }, [jumpSection]);

  useEffect(() => {
    if (onLandingSection) {
      animateInLanding();
    }
  }, [onLandingSection]);

  useEffect(() => {
    // if (onLandingSection) {
    if (menuOpen) {
      // opened on landing section
      animateOutMenuLanding();
    } else {
      // closed on landing section
      animateInMenuLanding();
    }
    // }
  }, [menuOpen]);

  // ============== ANIMATIONS ================= //

  // animates the scroll indicator out and back in when jump section occurs
  const animateJumpSection = () => {
    gsap
      .timeline()
      .add(
        gsap.to(combinedRef.current, {
          duration: 0.75,
          ease: Back.easeOut,
          x: 200,
        })
      )
      .add(
        gsap.to(combinedRef.current, {
          duration: 0.75,
          delay: 0.15,
          ease: Back.easeInOut,
          x: 0,
        })
      );
  };

  // animates back in on the landing page
  const animateInLanding = () => {
    gsap.set(combinedRef.current, { x: 0 });
    gsap.to(combinedRef.current, {
      delay: 0.4,
      duration: 0.35,
      ease: Cubic.easeOut,
      y: 0,
      opacity: 1,
    });
  };

  // animates out when going to homescrollable
  const animateOutLanding = () => {
    gsap.to(combinedRef.current, {
      duration: 0.35,
      ease: Cubic.easeIn,
      y: -250,
      opacity: 0,
      onComplete: () => {
        gsap.set(combinedRef.current, { x: -9000 });
      },
    });
  };

  // animates out when the menu is opened
  const animateOutMenuLanding = () => {
    const scrollIndicator = gsap.to(combinedRef.current, {
      duration: 0.35,
      ease: Cubic.easeIn,
      x: 50,
      opacity: 0,
      // onComplete: () => {
      //   gsap.set(combinedRef.current, {
      //     x: -999999,
      //   });
      // },
    });
  };

  // animates back in when the menu is closed
  const animateInMenuLanding = () => {
    // gsap.set(combinedRef.current, {
    //   x: 100,
    // });
    const scrollIndicator = gsap.to(combinedRef.current, {
      duration: 0.35,
      delay: 0.2,
      ease: Cubic.easeOut,
      x: 0,
      opacity: 1,
    });
  };

  

  return (
    <div ref={combinedRef} className={styles.scrollIndicator}>
      <h5>{scrollDownHover ? 'Next' : 'Scroll'}</h5>
      <ArrowDown ref={arrowRef} animated={true} />
    </div>
  );
};

export default forwardRef(ScrollDownIndicator);
