import React, { useEffect, useState, useRef, forwardRef } from 'react';

const useChatBot = () => {
  const chatBotRef = useRef();

  // useEffect(() => {
    // <div data-tf-popover="qy4LAC4M" data-tf-button-color="#0445AF" data-tf-button-text="Launch me" data-tf-iframe-props="title=Client Health" data-tf-medium="snippet" style="all:unset;"></div><script src="//embed.typeform.com/next/embed.js"></script>
  //   const script = document.createElement('script');
  //   const botScript = `
  //       (function (d, w, c) {
  //         if (!d.getElementById('spd-busns-spt')) {
  //           var n = d.getElementsByTagName('script')[0],
  //             s = d.createElement('script');
  //           var loaded = false;
  //           s.id = 'spd-busns-spt';
  //           s.async = 'async';
  //           s.setAttribute('data-self-init', 'false');
  //           s.setAttribute('data-init-type', 'opt');
  //           s.src = 'https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js';
  //           s.setAttribute(
  //             'data-client',
  //             '8efff35e79a2d2625e1ebf85ab871105f386a2af'
  //           );
  //           s.setAttribute(
  //             'data-bot-hash',
  //             '4746b65a1c7f97aa401409e67d1156bcbd89a2fc'
  //           );
  //           s.setAttribute('data-env', 'prod');
  //           s.setAttribute('data-region', 'euc');
  //           if (c) {
  //             s.onreadystatechange = s.onload = function () {
  //               if (!loaded) {
  //                 c();
  //               }
  //               loaded = true;
  //             };
  //           }
  //           n.parentNode.insertBefore(s, n);
  //         }
  //       })(document, window, function () {
  //         Freshbots.initiateWidget(
  //           {
  //             autoInitChat: false,
  //             getClientParams: function () {
  //               return;
  //             },
  //           },
  //           function (successResponse) {},
  //           function (errorResponse) {}
  //         );
  //       });`;
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.innerHTML = botScript;
  //   document.body.appendChild(script);
  //   const ref = document.getElementById('mobile-chat-container');
  //   chatBotRef.current = ref;
  // }, []);

  return chatBotRef;
};

export default useChatBot;
