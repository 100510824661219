import { Widget, PopupButton, SliderButton } from '@typeform/embed-react';
import * as styles from './index.module.scss';
import React, { useEffect, useState, useRef, forwardRef } from 'react';
const TypeformChat = () => {
  return (
    <SliderButton id='l56iJtWS' className={styles.chatButton}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.2216 15.5566H9.77659C6.58438 15.5566 3.99658 12.9688 3.99658 9.77659V9.77659C3.99658 6.58438 6.58438 3.99658 9.77659 3.99658H13.6006C17.1367 3.99658 20.0032 6.86315 20.0032 10.3992V10.3992C20.0058 12.6228 18.8545 14.6885 16.962 15.8559L10.2216 20.0032V15.5566'
          stroke='#2E2F36'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SliderButton>
  );
};

export default forwardRef(TypeformChat);
