import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

const SEO = ({ title, description, shareImage, keywords, preventIndexing }) => {
  const { pathname } = useLocation();

  // default values
  const siteMetaData = {
    defaultTitle: 'Lucky Beard | Global Design and Advisory Company',
    siteUrl: 'https://luckybeard.com',
    defaultKeywords:
      'business strategy, product design, ui design, ui ux design, brand positioning, brand strategy, corporate strategy, user experience, web design london, web design dublin, web design south africa, user experience design',
    defaultDescription:
      "Hello. We're Lucky Beard. We make things that change things. Business strategy, brand strategy, product design, UI and UX design, and web design for your business.",
    defaultImage:
      'https://lucky-beard-strapi-bucket.s3.eu-west-1.amazonaws.com/lucky_beard_meta_image_d889749916.png',
    twitterUsername: '',
  };

  const seo = {
    title: title || siteMetaData.defaultTitle,
    keywords: keywords || siteMetaData.defaultKeywords,
    description: description || siteMetaData.defaultDescription,
    image: shareImage || siteMetaData.defaultImage,
    url: `${siteMetaData.siteUrl}${pathname}`,
    twitterUsername: siteMetaData.twitterUsername,
  };

  return (
    <Helmet title={seo.title}>
      <meta name='description' content={seo.description} key='description' />
      <meta name='keywords' content={seo.keywords} />
      <meta name='image' content={seo.image} />
      <link rel='canonical' href={seo.url} />
      {/* OG TAGS FOR FACEBOOK */}
      <meta property='og:url' content={seo.url} key='og:url' />
      <meta property='og:title' content={seo.title} key='og:title' />
      <meta
        property='og:description'
        content={seo.description}
        key='og:description'
      />
      <meta property='og:image' content={seo.image} key='og:image' />
      {/* {(article ? true : null) && <meta property='og:type' content='article' />} */}
      {/* TWITTER  */}
      {seo.twitterUsername && (
        <meta name='twitter:creator' content={seo.twitterUsername} />
      )}
      <meta
        name='twitter:card'
        content='summary_large_image'
        key='twitter:card'
      />
      {seo.title && <meta name='twitter:title' content={seo.title} />}
      {seo.description && (
        <meta name='twitter:description' content={seo.description} />
      )}
      {seo.image && <meta name='twitter:image' content={seo.image} />}
      {(preventIndexing || process.env.GATSBY_ENVIRONMENT !== 'production') && (
        <meta name='robots' content='noindex' />
      )}
      {(preventIndexing || process.env.GATSBY_ENVIRONMENT !== 'production') && (
        <meta name='googlebot' content='noindex' />
      )}
      {process.env.GATSBY_ENVIRONMENT === 'production' && (
        <meta
          name='google-site-verification'
          content='apBLovLA5Zk22_Dkqbf3ZV5UBOTe3nvFOB9NsQyaFp0'
        />
      )}
    </Helmet>
  );
};
export default SEO;
