import React, { forwardRef, useEffect, useRef } from 'react';
import * as styles from './index.module.scss';
import MenuButton from './menu-button';
import WipeLink from '../nav-transitions/wipe-link';
import Logo from '../logo';
import gsap from 'gsap';
import useCombinedRefs from '../../hooks/useCombinedRefs';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useDispatch, useSelector } from 'react-redux';
import { closeMenu } from '../../state/ui';
import { isMobile } from 'react-device-detect';
import {useTransitionState} from 'gatsby-plugin-transition-link/hooks';
gsap.registerPlugin(ScrollTrigger);
const TopNav = ({ hideLogo }, ref) => {
  const transitionState = useTransitionState();
  
  const dispatch = useDispatch();
  const menuOpen = useSelector((state) => state.ui.menuOpen || false);
  const combinedRef = useCombinedRefs(ref);
  const timeLine = useRef();
  const scrolledBelow = useRef(false);


  useEffect(() => {
    // becase the transition delays unmounting, regular unmount in useeffect is fired later than the new mount
    // the alternative is to make use of the useTransitionState Hook to find out the state of the component transition
    if (transitionState.transitionStatus === 'exited') {
      if (timeLine.current) {
        timeLine.current.kill();
        if (timeLine.current.scrollTrigger) {
          timeLine.current.scrollTrigger.kill();
        }
      }
       
      if (ScrollTrigger) {
       ScrollTrigger.getAll().forEach(st => {
          st.kill();
        });
      }
      timeLine.current = null;
    }

    if (transitionState.transitionStatus === 'entered') {
      ScrollTrigger.refresh();

    }
  }, [transitionState.transitionState]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    timeLine.current = gsap.timeline({
      scrollTrigger: {
        trigger: 'body',
        start: '3px 0',
        toggleActions: 'play none none reverse',
      },
    });
    timeLine.current.add(
      gsap.to(combinedRef.current, {
        backgroundColor: 'white',
        duration: 0.25,
        paddingTop: 10,
        paddingBottom: 10,
        onComplete: () => {
          scrolledBelow.current = true;
        },
        onReverseComplete: () => {
          scrolledBelow.current = false;
        },
      }),
      0
    );
    timeLine.current.add(
      gsap.to(combinedRef.current.firstChild, {
        duration: 0.15,
        scale: 0.75,
      }),
      0
    );
    return () => {
      console.log('Unmounted');
    };

  }, []);

  useEffect(() => {
    if (menuOpen) {
      gsap.to(combinedRef.current, {
        backgroundColor: 'rgba(255,255,255,0)',
      });
    } else {
      if (scrolledBelow.current) {
        gsap.to(combinedRef.current, {
          backgroundColor: 'rgba(255,255,255,1)',
        });
      }
    }
  }, [menuOpen]);

  return (
    <div ref={combinedRef} className={`${styles.container} topnav`}>
      {/* <span className={`${styles.link} ${styles.active}`}>Introduction</span>
      <span className={styles.link}>Services</span>
      <span className={styles.link}>Our work</span> */}
      <WipeLink to='/'>
        <div
          onClick={() => {
            dispatch(closeMenu());
          }}
          className={`${styles.logoWrapper} ${
            hideLogo && styles.hideLogo
          } logo-wrapper`}
        >
          <Logo />
        </div>
      </WipeLink>
      <div className={`${styles.menuButtonWrapper}`}>
        <MenuButton />
      </div>
    </div>
  );
};

export default forwardRef(TopNav);
