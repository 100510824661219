import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import arrowHead from '../../assets/images/arrowhead.svg';
import { StaticImage } from 'gatsby-plugin-image';
import gsap, { Elastic, Bounce, Cubic } from 'gsap';
const ArrowDown = ({ onClick, darkColor }, ref) => {
  const arrowRef = useRef();

  return (
    <div
      ref={ref}
      className={styles.arrowWrapper}
      onClick={() => {
        onClick();
      }}
    >
      <div
        ref={arrowRef}
        className={`${styles.mainLine} ${darkColor ? styles.dark : ''}`}
      >
        <img alt="the head of a vector arrow" src={arrowHead} className={`${styles.arrowHead}`} />
      </div>
    </div>
  );
};

export default forwardRef(ArrowDown);
