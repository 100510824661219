import React, { useEffect, useState, useRef, forwardRef } from 'react'
import * as styles from './index.module.scss'

const Squiggly = ({}, ref) => {
  return (
    <div ref={ref}>
      <svg
        width='21'
        height='83'
        viewBox='0 0 21 83'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.0938 1L1 5.74828L20 14.6862L1 23.6241L20 32.5621L1 41.5L20 50.4379L1 59.3759L20 68.3138L1 77.2517L11.0938 82'
          stroke='black'
          strokeLinecap='round'
          strokeLinejoin='bevel'
        />
      </svg>
    </div>
  )
}

export default forwardRef(Squiggly)
